import { media, color, Button, InputText } from "@sencrop/ui-components";
import { WebTitle, webtext } from "@sencrop/ui-web";
import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import BackgroundElement from "../components/BackgroundElement";
import LinkElement from "../containers/LinkElement";
import ButtonElement from "../containers/ButtonElement";
import { blockCss, containerCss, contentCss } from "../helpers/style";
import { Link } from "../containers/LinkRenderer";

const SectionNetwork = ({
  subhead,
  title,
  description,
  link,
  image,
  button,
  whiteBackgroundPercentage,
  searchPlaceHolder,
  searchSubmitLabel,
}: GatsbyTypes.SectionNetworkFragmentFragment) => {
  return (
    <Background whiteBackgroundPercentage={whiteBackgroundPercentage || 100}>
      <Wrapper>
        {subhead && (
          <SubHead>
            <WebTitle
              sizes={["xxl", "xxxxxl", "xxxxxl", "xxxxxl"]}
              color="branding-secondary-light"
              as="h2"
              aria-hidden={true}
            >
              {subhead}
            </WebTitle>
          </SubHead>
        )}
        <BackgroundElement
          backgroundImage={image?.gatsbyImageData}
          backgroundPosition="center center"
          style={{
            borderRadius: "10px",
            boxShadow: "0 4px 10px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <ContentContainer>
            <TitleContainer>
              <WebTitle sizes={["l", "xl", "xl", "xl"]} color="primary">
                {title}
              </WebTitle>
            </TitleContainer>
            {description?.childMarkdownRemark?.html && (
              <Description
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html,
                }}
                style={{ whiteSpace: "pre-wrap" }}
              />
            )}
            {searchPlaceHolder && searchSubmitLabel && (
              <InputContainer>
                <InputText
                  label={searchPlaceHolder}
                  size="xl"
                  endButton={
                    <Button color="reverse">{searchSubmitLabel}</Button>
                  }
                  placeholder={searchPlaceHolder}
                  style={{ boxShadow: "none" }}
                />
              </InputContainer>
            )}
            {button && (
              <ButtonContainer>
                <ButtonElement centered link={button} size="xl">
                  {button.label}
                </ButtonElement>
              </ButtonContainer>
            )}
            {link && (
              <LinkContainer>
                <LinkElement centered link={link as Link} color="green">
                  {link.label}
                </LinkElement>
              </LinkContainer>
            )}
          </ContentContainer>
        </BackgroundElement>
      </Wrapper>
    </Background>
  );
};

export default SectionNetwork;

export const fragment = graphql`
  fragment SectionNetworkFragment on ContentfulSectionNetwork {
    title
    subhead
    link {
      ...LinkFragment
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    image {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
    }
    whiteBackgroundPercentage
    searchPlaceHolder
    searchSubmitLabel
    button {
      ...LinkFragment
    }
  }
`;

type BackgroundProps = {
  whiteBackgroundPercentage: number;
};

const Background = styled.div<BackgroundProps>`
  position: relative;
  :after {
    z-index: -1;
    content: "";
    position: absolute;
    background-color: ${color("background-primary")};
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${(props) => `${100 - props.whiteBackgroundPercentage}%`};
  }
`;

const Wrapper = styled.div`
  ${containerCss}
  ${contentCss}
  ${blockCss}
  z-index: 2;
  text-align: center;
  padding: 2.5rem 1rem 2rem 1rem;
  ${media.greaterThan("tablet")`
    padding: 3.5rem 2rem;
  `}
`;

const SubHead = styled.div`
  word-wrap: break-word;
  margin: auto;
  position: relative;
  bottom: -1rem;
  margin-top: -2rem;
  ${media.greaterThan("tablet")`
    margin-top: -2.5rem;
    bottom: -2rem;
  `}
`;

const ContentContainer = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.8rem 1rem;
  ${media.greaterThan("tablet")`
    padding: 3.375rem 10.25rem;
  `}
`;

const TitleContainer = styled.div`
  margin-bottom: 0.5rem;
  ${media.greaterThan("tablet")`
    margin-bottom: 1rem;
  `}
`;

const Description = styled.div`
  margin-bottom: 1rem;
  p {
    color: ${color("text-primary:75")};
    ${webtext("text", "l")};
  }
`;

const InputContainer = styled.div`
  margin-top: 1rem;
  label {
    position: absolute;
    left: -999em;
  }
  ${media.greaterThan("tablet")`
    margin: 2rem auto 0 auto;
    width: 75%;
  `}
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  ${media.greaterThan("tablet")`
    margin-top: 2rem;
  `}
`;

const LinkContainer = styled.div`
  margin-top: 1rem;
  ${media.greaterThan("tablet")`
    margin-top: 2rem;
  `}
`;
